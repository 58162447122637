// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import Rails from "@rails/ujs"
import "./reservation"
//Swiper import
import Swiper from 'swiper';
import {Scrollbar} from 'swiper/modules';
// import {Navigation} from 'swiper/modules';
// import "../assets/stylesheets/base/swiper.css";
import {Fancybox} from "@fancyapps/ui";

window.Rails = Rails

Rails.start()

// Слайдер
function initSliders() {
    if (document.querySelector(".feedback__swiper")) {
        new Swiper('.feedback__swiper', {
            spaceBetween: 4,
            autoHeight: false,
            speed: 800,
            slidesPerView: 'auto',
            grabCursor: true,
            centeredSlides: true,
            breakpoints: {
                479.98: {
                    spaceBetween: 8,
                },
                767.98: {
                    spaceBetween: 12,
                },
                991.98: {
                    spaceBetween: 16,
                },
            },
        });
    }
    if (document.querySelector(".places__slider")) {
        new Swiper(".places__slider", {
            modules: [Scrollbar],
            slidesPerView: 'auto',
            spaceBetween: 8,
            autoHeight: false,
            speed: 800,
            grabCursor: true,
            breakpoints: {
                767.98: {
                    spaceBetween: 16,
                },
                991.98: {
                    spaceBetween: 24,
                },
            },
            scrollbar: {
                el: ".places__swiper-scrollbar",
            },
        });
    }
    if (document.querySelector(".visitors__slider")) {
        new Swiper(".visitors__slider", {
            modules: [Scrollbar],
            slidesPerView: 'auto',
            spaceBetween: 6,
            autoHeight: false,
            speed: 800,
            grabCursor: true,
            breakpoints: {
                767.98: {
                    spaceBetween: 16,
                },
                991.98: {
                    spaceBetween: 32,
                },
            },
            scrollbar: {
                el: ".visitors__swiper-scrollbar",
            },
        });
    }
}

//Бургер
function toggleMenu() {
    const burger = document.querySelector('#burger')
    const menu = document.querySelector('#mobile-menu')
    const body = document.querySelector('body')

    burger.addEventListener('click', () => {
        burger.classList.toggle('active')
        menu.classList.toggle('hidden')
        menu.classList.toggle('flex')
        body.classList.toggle('overflow-hidden')
    })

    window.addEventListener('resize', () => {
        if (window.innerWidth > 767.98) {
            menu.classList.add('hidden')
            menu.classList.remove('flex')
            burger.classList.remove('active')
            body.classList.remove('overflow-hidden')
        }
    })
}

let unlock = true;
const timeout = 800;


function setDeviceType() {
    // This is just a pseudo-code. Adjust it according to your situation.

    let screenWidth = window.innerWidth;
    let deviceType = 'pc';

    if (screenWidth <= 767.98) {
        deviceType = 'mobile';
    }

    // Send request to server
    Rails.ajax({
        type: "POST",
        url: '/sessions/set_device_type',
        data: `device_type=${deviceType}`,
    });
}

document.addEventListener("turbo:load", function () {
    const promoVideo = document.getElementById('promoVideo');
    if (promoVideo) {
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    promoVideo.play();
                } else {
                    promoVideo.pause();
                }
            });
        }, {threshold: 0.1});

        observer.observe(promoVideo);
    }

    function playVideoWithInteraction(videoElement) {
        videoElement.play().then(() => {
        }).catch(error => {
            console.error('Помилка при відтворенні відео:', error);
        });
    }

    Fancybox.bind('[data-fancybox="gallery"]', {});

    initSliders();
    toggleMenu();
    setDeviceType();
    AOS.init();
    //window.addEventListener('resize', updateLayout);

    document.addEventListener("click", function (e) {
        const popupLink = e.target.closest('.popup-link');
        if (popupLink) {
            e.preventDefault();
            const popupName = popupLink.getAttribute('href').replace('#', '');
            const curentPopup = document.getElementById(popupName);
            popupOpen(curentPopup);
        }
    });

    document.addEventListener("click", function (e) {
        const popupCloseIcon = e.target.closest('.close-popup');
        if (popupCloseIcon) {
            e.preventDefault();
            popupClose(popupCloseIcon.closest('.popup'));
        }
    });

    document.addEventListener('keydown', function (e) {
        if (e.which === 27) {
            const popupActive = document.querySelector('.popup.open');
            popupClose(popupActive);
        }
    });
    if (document.querySelectorAll('[data-hidden]')) {
        const tarrifsSwiper = document.querySelector('.tarrifs__swiper');
        const tarrifsSlides = document.querySelectorAll('.tarrifs__slide');
        const hiddenBlocks = document.querySelectorAll('[data-hidden]');
        if (tarrifsSwiper && tarrifsSlides.length === 0) {
            hiddenBlocks.forEach((block) => {
                block.style.display = 'none';
            });
        }
    }
});

function popupOpen(curentPopup) {
    if (curentPopup && unlock) {
        const popupActive = document.querySelector('.popup.open');
        if (popupActive) {
            popupClose(popupActive, false);
        } else {
            bodyLock();
        }
        curentPopup.classList.add('open');
        curentPopup.addEventListener("click", function (e) {
            if (!e.target.closest('.popup__content')) {
                popupClose(e.target.closest('.popup'));
            }
        });
    }
}

function popupClose(popupActive, doUnlock = true) {
    if (unlock) {
        popupActive.classList.remove('open');
        if (doUnlock) {
            bodyUnLock();
        }
    }
}

function bodyLock() {
    const lockPaddingValue = window.innerWidth - document.querySelector('.wrapper').offsetWidth + 'px';

    const lockPaddingElements = document.querySelectorAll(".lock-padding");
    lockPaddingElements.forEach((el) => {
        el.style.paddingRight = lockPaddingValue;
    });

    const body = document.querySelector('body');
    body.style.paddingRight = lockPaddingValue;
    body.classList.add('lock');

    unlock = false;
    setTimeout(function () {
        unlock = true;
    }, timeout);
}

function bodyUnLock() {
    setTimeout(function () {
        const lockPaddingElements = document.querySelectorAll(".lock-padding");
        lockPaddingElements.forEach((el) => {
            el.style.paddingRight = '0px';
        });

        const body = document.querySelector('body');
        body.style.paddingRight = '0px';
        body.classList.remove('lock');
    }, timeout);

    unlock = false;
    setTimeout(function () {
        unlock = true;
    }, timeout);
}

window.locale = document.querySelector('html').getAttribute('lang');