import {Controller} from "@hotwired/stimulus"
import Flatpickr from "stimulus-flatpickr"

export default class extends Flatpickr {
    initialize() {
        super.initialize();
        this.getPartiallyFilledDates();
    }

    getPartiallyFilledDates() {
        let json_dates = this.data.element.dataset["partiallyFilledDates"];
        if (json_dates) {
            this.partially_filled_dates = JSON.parse(json_dates);
        } else {
            this.partially_filled_dates = [];
        }
    }

    connect() {
        this.config = {
            ...super.config,
            onDayCreate: this.highlightDays.bind(this),
            disable: this.data.element.dataset["disable"] ? JSON.parse(this.data.element.dataset["disable"]) : [],
            onChange: this.validateDates.bind(this),
        }

        super.connect();
    }

    highlightDays(selectedDates, dateStr, instance, cell) {
        const date = instance.formatDate(cell.dateObj, "Y-m-d");
        if (this.partially_filled_dates.includes(date)) {
            cell.classList.add("partially-filled");
        }
    }

    validateDates(selectedDates, dateStr) {

        let first_date = selectedDates[0];
        let second_date = selectedDates[1];

        if (first_date && second_date && first_date !== second_date) {
            let first_date_str = `${first_date.getFullYear()}-${(first_date.getMonth() + 1) > 9 ? first_date.getMonth() + 1 : `0${first_date.getMonth() + 1}`}-${first_date.getDate()}`;
            let second_date_str = `${second_date.getFullYear()}-${(second_date.getMonth() + 1) > 9 ? second_date.getMonth() + 1 : `0${second_date.getMonth() + 1}`}-${second_date.getDate()}`;

            if (first_date_str !== second_date_str && (this.partially_filled_dates.includes(first_date_str) || this.partially_filled_dates.includes(second_date_str))) {
                this.fp.clear();
                alert("Partial dates cannot be selected.");
            }
        }

        changeAvailableHours.bind(this)();
    }

    isPartiallyFilledDate(dateStr) {
        return this.partially_filled_dates.includes(dateStr);
    }

}

function changeAvailableHours(change_start_time = true) {
    if (change_start_time) {
        document.querySelector("#reservation_start_time").innerHTML = "";
        document.querySelector("#reservation_end_time").innerHTML = "";
    }

    Rails.ajax({
        url: "/reservations/available_hours",
        type: "GET",
        data: new URLSearchParams({
            reservation_date: document.querySelector("#reservation_reservation_date").value,
            start_time: document.querySelector("#reservation_start_time").value,
        }).toString(),
        dataType: "json",
        success: function (data) {
            console.log(data);

            let start_hours = data["start_hours"];
            let end_hours = data["end_hours"];

            if (change_start_time) {
                //     Set collection of available hours
                let start_time = document.querySelector("#reservation_start_time");
                start_time.innerHTML = "";

                start_time.appendChild(document.createElement("option"));

                start_hours.forEach(function (hour) {
                    let option = document.createElement("option");

                    if (hour < 10)
                        hour = "0" + hour;

                    option.value = `${hour}:00`;
                    option.text = `${hour}:00`;

                    start_time.appendChild(option);
                });
            }

            let end_time = document.querySelector("#reservation_end_time");
            end_time.innerHTML = "";

            end_time.appendChild(document.createElement("option"));

            end_hours.forEach(function (hour) {
                let option = document.createElement("option");

                if (hour < 10)
                    hour = "0" + hour;

                option.value = `${hour}:00`;
                option.text = `${hour}:00`;

                end_time.appendChild(option);
            });
        },
        error: function (data) {
            console.log(data);
        }
    });
}

window.changeAvailableHours = changeAvailableHours;