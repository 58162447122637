import {Application} from "@hotwired/stimulus"

// import Flatpickr
import Flatpickr from './flatpickr_controller.js'

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus = application

export {application}


// Manually register Flatpickr as a stimulus controller
application.register('flatpickr', Flatpickr)
